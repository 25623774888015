import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// const token = localStorage.getItem('token');
axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

// export function setBearerToken(token) {
//   axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }

export default axiosInstance;
