import headerlogo from '../../../assets/images/QuickBooks-white-logo.png';
import {
    Header,
    Visibility,
    Box,
    IconButton,
    AlignItems,
    ButtonVariant,
    Glyph,
    Space,
    Dropdown,
    Button,
    Menu,
    MenuRole,
    MenuDensity,
    JustifyContent
} from '@gocardless/flux-react';
import { Navigate } from 'react-router';
import { useNavigate } from "react-router-dom";


export default function HeaderLayout(handleOpen) {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate()
    if (!userDetails) {
        return <Navigate to={'/login'} />;
    }


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return navigate('/',{state:{logout:true}})
    };

    return (
        <Header gridArea="topnav"  className={"gcl-nav"}>
            <Box spaceBelow={2} layout="flex" alignItems={AlignItems.Center} justifyContent={JustifyContent.SpaceBetween} maxWidth={["320px", "100%", "100%", "100%", "100%"]}>
                <Box layout='flex'>
                    <Visibility visible={['block', null, null, 'none']}>
                        <IconButton
                            label=""
                            variant={ButtonVariant.TextOnDark}
                            icon={Glyph.Menu}
                            onClick={() => handleOpen.handleOpen(prev=>!prev)}
                        />
                    </Visibility>
                    <Space h={[0, null, null, 0]} layout="inline" />
                    <Box
                        alignSelf={AlignItems.Center}
                        alignItems={AlignItems.Center}
                        spaceAbove={0.5}
                    >
                        <img src={headerlogo} alt={"headerlogo"}></img>
                    </Box>
                </Box>

                {/* Header start from here */}

                <Box>
                    <Dropdown
                        controls="sample-content"
                        trigger={(props) => {
                            return (
                                <Button
                                    {...props}
                                    rightIcon={Glyph.ChevronDown}
                                    variant={ButtonVariant.TextOnDark}
                                    className={'comapny-name-top'}
                                >
                                    {userDetails?.name || 'Name'}
                                    {/* Name */}
                                </Button>
                            );
                        }}
                        maxWidth={'192px'}
                    >
                        <Box width={'192px'}   className={'comapny-name-top'}>
                            <Menu.Root
                                id="sample-content"
                                role={MenuRole.Menu}
                                density={MenuDensity.Compact}
                                // className={'comapny-name-top'}
                            >
                                <Menu.Button
                                    onClick={() => navigate('/account')}>Account</Menu.Button>
                                <Menu.Button onClick={handleLogout}>Log out</Menu.Button>
                            </Menu.Root>
                        </Box>
                    </Dropdown>
                </Box>
            </Box>
        </Header>
    );
}
