
import { Helmet } from 'react-helmet-async';
import Hero from './Hero';
import { AlignItems, Box, Overflow } from '@gocardless/flux-react';


function CollectPayments() {
  return (
    <Box
      overflowY={Overflow.Auto}
      overflowX={Overflow.Hidden}
      flexGrow={1}
      alignItems={AlignItems.Center}
      height={'100%'}
      className={'customer-onboarding-bg'}
    >
      <Helmet>
        <title>Collect Payments</title>
      </Helmet>
      <>
        <Hero />
      </>
    </Box>
  );
}

export default CollectPayments;
