import {Box} from '@mui/material';
import {Helmet} from 'react-helmet-async';

import {styled} from '@mui/material/styles';
import Hero from './Hero';

const OverviewWrapper = styled(Box)(
    () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

function Account() {
    return (
        <OverviewWrapper>

            <Helmet>
                <title>Account</title>
            </Helmet>
            <Box>
                <Hero/>
            </Box>
        </OverviewWrapper>
    );
}

export default Account;
