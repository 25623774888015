import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { MainArea } from '@gocardless/flux-react';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return <MainArea height={'100%'}>{children || <Outlet />}</MainArea>;
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
