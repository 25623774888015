import {
  ButtonLayout,
  Menu,
  MenuRole,
  Button,
  ButtonVariant,
  TypePreset,
  P,
  Box,
  JustifyContent
} from '@gocardless/flux-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function index() {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const splitLocation = pathname.split('/');

  console.log('Split', splitLocation);
  return (
    <Box
      height={'90vh'}
      layout="flex"
      flexDirection={'column'}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Menu.Root role={MenuRole.List} className="final">
        <Menu.Link
          className={splitLocation[1] === 'dashboard' && 'active'}
          onClick={() => navigate('/dashboard')}
          accent={splitLocation[1] === 'dashboard' ? 'visible' : 'hidden'}
        >
          <P fontFamily="sans-serif">Dashboard</P>
        </Menu.Link>
        <Menu.Link
          className={splitLocation[1] === 'customers' && 'active'}
          onClick={() => navigate('/customers')}
          accent={splitLocation[1] === 'customers' ? 'visible' : 'hidden'}
        >
          <P fontFamily="sans-serif"> Customers</P>
        </Menu.Link>
        <Menu.Link
          className={splitLocation[1] === 'invoices' && 'active'}
          onClick={() => navigate('/invoices')}
          accent={splitLocation[1] === 'invoices' ? 'visible' : 'hidden'}
        >
          <P fontFamily="sans-serif">Invoices</P>
        </Menu.Link>
        <Menu.Link
          className={splitLocation[1] === 'refunds' && 'active'}
          onClick={() => navigate('/refunds')}
          accent={splitLocation[1] === 'refunds' ? 'visible' : 'hidden'}
        >
          <P fontFamily="sans-serif">Refunds</P>
        </Menu.Link>
        <Menu.Link
          className={splitLocation[1] === 'reconciliation' && 'active'}
          onClick={() => navigate('/reconciliation')}
          accent={splitLocation[1] === 'reconciliation' ? 'visible' : 'hidden'}
        >
          <P fontFamily="sans-serif">Reconciliation</P>
        </Menu.Link>
        <Menu.Expander
          className={splitLocation[1] === 'settings' && 'active'}
          title="Settings"
          initialExpanded={splitLocation[1] === 'settings'}
        >
          <Menu.Expander
            className={splitLocation[2] === 'quickbooks' && 'active'}
            title="QuickBooks"
            initialExpanded={splitLocation[2] === 'quickbooks'}
          >
            <Menu.Link
              // href="/settings/quickbooks/connected-apps"
              className={splitLocation[3] === 'connected-apps' && 'active'}
              onClick={() => navigate('/settings/quickbooks/connected-apps')}
              accent={
                splitLocation[3] === 'connected-apps' ? 'visible' : 'hidden'
              }
            >
              <P fontFamily="sans-serif">Connected apps</P>
            </Menu.Link>
            <Menu.Link
              // href="/settings/quickbooks/automation"
              className={splitLocation[3] === 'automation' && 'active'}
              onClick={() => navigate('/settings/quickbooks/automation')}
              accent={splitLocation[3] === 'automation' ? 'visible' : 'hidden'}
            >
              <P fontFamily="sans-serif">Automation</P>
            </Menu.Link>
            <Menu.Link
              // href="/settings/quickbooks/reconciliation"
              className={splitLocation[3] === 'reconciliation' && 'active'}
              onClick={() => navigate('/settings/quickbooks/reconciliation')}
              accent={
                splitLocation[3] === 'reconciliation' ? 'visible' : 'hidden'
              }
            >
              <P fontFamily="sans-serif">Reconciliation</P>
            </Menu.Link>
          </Menu.Expander>
          <Menu.Expander
            title="GoCardless"
            className={splitLocation[2] === 'gocardless' && 'active'}
            initialExpanded={splitLocation[2] === 'gocardless'}
          >
            <Menu.Link
              // href="/settings/gocardless/general"
              className={splitLocation[3] === 'general' && 'active'}
              onClick={() => navigate('/settings/gocardless/general')}
              accent={splitLocation[3] === 'general' ? 'visible' : 'hidden'}
            >
              <P fontFamily="sans-serif">General settings</P>
            </Menu.Link>
            <Menu.Link
              className={splitLocation[3] === 'teams' && 'active'}
              onClick={() => navigate('/settings/gocardless/teams')}
              accent={splitLocation[3] === 'teams' ? 'visible' : 'hidden'}
            >
              <P fontFamily="sans-serif">Team Setting</P>
            </Menu.Link>
          </Menu.Expander>
          <Menu.Link href="">Legal</Menu.Link>
          <Menu.Link href="https://hub.gocardless.com/s/knowledge-base?language=en_GB">
            Help centre
          </Menu.Link>
          <Menu.Link href="https://manage.gocardless.com/">
            <P fontFamily="sans-serif">GoCardless dashboard</P>
          </Menu.Link>
        </Menu.Expander>
      </Menu.Root>

      <Box spaceBelow={0} gutterH={2} className="sidebar-button-box">
        <Button
          className={'support-btn sidebar-support-button'}
          layout={[ButtonLayout.Inline, null, ButtonLayout.Inline]}
          variant={ButtonVariant.PrimaryOnLight}
          onClick={() =>
            (window.location.href =
              'https://support.gocardless.com/hc/en-gb/categories/360000103845')
          }
        >
          <P preset={TypePreset.Body_01}>Get support</P>
        </Button>
      </Box>
    </Box>
  );
}

export default index;
