import {useNavigate} from 'react-router-dom';

import blacklogo from '../../../../assets/images/blacklogo.svg';
import AuthorisationWorkImage from '../../../../assets/images/authorisationWork.png';

import axios from '../../../../utils/axios';
import {
    Box,
    Button,
    ButtonVariant,
    Color,
    FontWeight,
    JustifyContent,
    P,
    Text,
    XYGrid,
    ButtonSize,
    ProgressBar,
    ProgressBarVariant,
    ColorScheme,
    TypeScale,
    Space,
    FieldSet,
    Radio,
    ToggleControlPosition,
    ToggleVariant,
    Icon,
    Glyph,
    PlainLink,
    Toast,
    useToast,
    BannerVariant,
    BannerStatus
} from '@gocardless/flux-react';
import React from 'react';

// ----------------------------------------------------------------------

export default function Hero() {
    const navigate = useNavigate();
    const max = 3;
    const [automaticRequest, setAutomaticRequest] = React.useState('true');
    const value = 2;
    const {toast, dismissToast} = useToast();

    const Tost = (title, message, status) =>
        toast((t) => (
            <Toast
                id={`toast_${t.id}`}
                title={title}
                variant={BannerVariant.Light}
                status={status}
                closeAction={{
                    onClose: () => dismissToast(t.id),
                    label: 'Close'
                }}
            >
                {message}
            </Toast>
        ));
    const saveAutmaticEmail = async () => {
        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post('/setting/saveQuickbooksAutomation', {
                quickbookAutomation: {
                    IsAutomaticallyEmailNewCustomerForDirectDebit:
                        automaticRequest == 'true'
                }
            });
            if (response) {
                Tost('Success', 'Setting updated', BannerStatus.Success);
                return navigate('/customer-onboarding/collect-payments');
            }
        } catch (err: any) {
            console.log(err.message);
        }
    };

    return (
        <XYGrid
            templateColumns="repeat(12, 1fr)"
            templateRows="repeat(2)"
            columnGap={1}
            rowGap={1}
            height={'100%'}
        >
            <Box
                gridColumn={['span 12', null, null, 'span 8']}
                layout="flex"
                justifyContent={JustifyContent.Center}
            >
                <Box>
                    <Box spaceAbove={2} spaceBefore={1}>
                        <img src={blacklogo} alt={'blacklogo'}/>
                    </Box>
                    <Box height="90%" borderRadius={1} layout="flex">
                        <Box spaceBefore={1} spaceAfter={1}>
                            <Box
                                spaceBelow={1}
                                spaceAbove={2}
                                maxWidth={['100%', null, '200px']}
                            >
                                <ProgressBar
                                    borderRadius={0}
                                    variant={ProgressBarVariant.Solid}
                                    colorScheme={ColorScheme.OnLight}
                                    value={value}
                                    max={max}
                                    label={
                                        <Text id="radiusDemo2">
                                            <P weight={400}
                                               className={'customer-onboarding-progress-color'}
                                               fontFamily={"sans-serif"}>Step {value} of {max}</P>
                                        </Text>
                                    }
                                    aria-labelledby="radiusDemo2"
                                />
                            </Box>
                            <Box spaceBelow={2} maxWidth={['100%', null, '800px']} className={"m-26"}>
                                <P size={7} weight={600} fontFamily="sans-serif" className={'login-header-text'}>
                                    Choose how to request authorisation to collect payments from
                                    your customers
                                </P>
                            </Box>

                            <Box
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '800px']}

                            >
                                <FieldSet className={'radio-fieldset'}>

                                    <Radio
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        value="true"
                                        checked={automaticRequest == 'true'}
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                        description="Every new customer you add on Quickbooks will receive an email request"

                                    >
                                        <Box  >
                                            <Text color={Color.Evergreen_800}>Recommended</Text>
                                            <Space v={1}/>
                                            <P size={6} fontFamily="sans-serif">
                                                {' '}
                                                Automatically request new customers you add to
                                                Quickbooks
                                            </P>
                                            <Space v={1}/>
                                        </Box>
                                    </Radio>
                                    <Radio
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        value="false"
                                        checked={automaticRequest == 'false'}
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                        description="You will need to send requests manually to the customer you choose using the GoCardless for Quickbooks dashboard"
                                    >
                                        <Box  >
                                            <P fontFamily="sans-serif" size={6}>Request manually</P>
                                            <Space v={1}/>
                                        </Box>
                                    </Radio>
                                </FieldSet>
                            </Box>
                            <Space v={1}/>

                            <Box maxWidth={['100%', null, '750px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    fontFamily="sans-serif"
                                    weight={400}
                                    color={Color.Greystone_DarkMatter} className={'customer-onboarding-gray-title-color'}
                                >
                                    You can change these settings at any time. Existing customers
                                    in your Quickbooks account won’t be contacted unless you
                                    manually request authorisation from them.
                                </P>
                            </Box>
                            <Space v={3}/>

                            <Button
                                variant={ButtonVariant.PrimaryOnLight}
                                size={ButtonSize.Sm}
                                onClick={
                                    () => saveAutmaticEmail()
                                    // navigate('/customer-onboarding/collect-payments')
                                }
                            >
                                Continue
                            </Button>

                            <Space v={2}/>
                            <Box layout="flex" >
                                <P>
                                    <Icon size="20px" name={Glyph.ChevronLeft}/>
                                </P>{' '}
                                <PlainLink
                                    href="javascript:void(0);"
                                    onClick={() => navigate('/customer-onboarding/GoCardless-fees')}
                                    decoration={'underline'}
                                    weight={FontWeight.Bold}
                                    className={'title-color'}
                                    fontFamily={'sans-serif'}
                                >
                                    Back
                                </PlainLink>
                            </Box>
                            <Space v={4}/>
                            <Box maxWidth={['100%', null, '750px']} >
                                <P
                                    lineHeight={TypeScale.Size_04}
                                    size={TypeScale.Size_04}
                                    weight={FontWeight.SemiBold}
                                    fontFamily="sans-serif"
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Get support
                                </P>
                                {/*<Space v={2}/>*/}

                                <P
                                    lineHeight={TypeScale.Size_02}
                                    size={TypeScale.Size_02}
                                    fontFamily="sans-serif"
                                    weight={400}
                                    // color={Color.Greystone_DarkMatter}
                                    className={'customer-onboarding-gray-title-color  mt-13'}
                                >
                                    GoCardless is a Bacs approved bureau and is authorised by the
                                    Financial Conduct Authority under the Payment Services
                                    Regulations 2009, registration number 597190, for the
                                    provision of payment services
                                </P>
                                <Space v={2}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn={['span 12', null, null, 'span 4']}
                height="100%"
                width={[null,null,null,"623px"]}
                bg={Color.Evergreen_1400}
            >
                <img
                    src={AuthorisationWorkImage}
                    height={'100%'}
                    width={'100%'}
                    alt={'AuthorisationWorkImage'}
                ></img>
            </Box>
        </XYGrid>
    );
}
