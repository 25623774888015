import React from 'react';
import { ThemeProvider } from '@gocardless/flux-react';
import '../App.css';
import '../Media.css';


const ThemeProviderWrapper: React.FC = (props) => {
  return <ThemeProvider>{props.children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
