/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const RouteGuest = ({ children }) => {
  const token = JSON.parse(localStorage.getItem('user'));
  // if (token) {
  //     return <Navigate to={"/dashboard"} />;
  // }
  return <>{children}</>;
};
export default RouteGuest;