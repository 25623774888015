import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Aside,
  XYGrid,
  Sidebar,
  Box,
  MainArea,
  JustifyContent,
  Footer,
  P,
  TypePreset,
  ColorPreset,
  FontWeight,
  AlignItems,
  TypeScale
} from '@gocardless/flux-react';
import SidebarMenu from './SidebarMenu';
import HeaderLayout from './Header';
interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <XYGrid
        height="300px"
        templateAreas={`
        'topnav topnav'
        'sidebar main'
        'footer footer'
      `}
        templateColumns=" max-content auto"
        // templateColumns="minmax(150px, max-content) 1fr"
        templateRows="60px 1fr auto"
      >
        <HeaderLayout handleOpen={setOpen} />
        <Aside className={'gcl-sidebar'}>
          <Sidebar
            open={open}
            closeAction={{ label: 'Close', onClose: () => setOpen(false) }}
          >
            <Box gutterV={1} height={'100%'} className="gcl-sidebar-box">
              <SidebarMenu />
            </Box>
          </Sidebar>
        </Aside>
        <MainArea
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          flexDirection={'column'}
          gutterH={[1, null, 1]}
          gutterV={1}
          spaceBefore={1}
          spaceAfter={1}
          // minHeight={'70vh'}
          minWidth={['370px', '768px', '768px', '1120px']}
        >
          <Box>
            <Outlet />
          </Box>
          <Footer
            gridArea="footer"
            layout="flex"
            flexDirection="column"
            justifyContent={JustifyContent.Center}
            alignItems={AlignItems.Center}
            className="my-footer"
          >
            {/* <Space v={1}/> */}
            <P
              preset={TypePreset.Body_02}
              color={ColorPreset.TextOnLight_03}
              size={TypeScale.Size_01}
              weight={FontWeight.Light}
              fontFamily="sans-serif"
            >
              Copyright 2023 GoCardless Ltd
            </P>
            {/* <Space v={1}/> */}
          </Footer>
        </MainArea>
      </XYGrid>
    </>
  );
};

export default SidebarLayout;
